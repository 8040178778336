import React from 'react';
import classnames from 'classnames';

import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Link from 'components/uiLibrary/Link'; // TODO: Change to use LinkButton
import Image from 'components/uiLibrary/Image';
import Typography from 'components/uiLibrary/Typography';
import LanguageSelector from 'components/Globals/LanguageSelector';

import { createDate } from 'utils/date';
import { summonLiveChat } from 'utils/hubspot';

import route from 'constants/routes';
import routesWithTitle from 'constants/routesWithTitle';
import { FACEBOOK, INSTAGRAM, LINKEDIN, TP, TWITTER } from 'constants/consts';
import { SECTIONS, COMPONENTS } from 'components/Globals/Analytics/constants';
import operaWhiteLogo from 'public/images/operabasewhite.svg';
import { useTranslation } from 'src/i18n';
import useTracking from 'components/Globals/Analytics';

import classes from './Footer.module.scss';

const socialLinks = [
  { link: FACEBOOK, type: 'facebook', title: 'Facebook' },
  { link: TWITTER, type: 'twitter', title: 'Twitter' },
  { link: INSTAGRAM, type: 'instagram', title: 'Instagram' },
  { link: LINKEDIN, type: 'linkedin', title: 'LinkedIn' },
];

const policyLinks = [
  {
    name: `${TP}.FN_PRIVPOL`,
    link: route.PRIVACY,
  },
  {
    name: `${TP}.FN_TERMCOND`,
    link: route.TERMS_AND_CONDITIOS,
  },
  {
    name: `${TP}.FN_CPOL`,
    link: route.COOKIE_POLICY,
  },
  {
    name: `${TP}.FN_TERMPAY`,
    link: route.PAYMENT_TERMS,
  },
  {
    name: 'Website Structure for AI and Bots',
    link: 'https://help.operabase.com/knowledge/en/understanding-operabase-website-structure',
    isText: true,
  },
];

const footerLinks = {
  performances: {
    title: `${TP}.m_PERF`,
    items: [
      routesWithTitle.PRODUCTIONS,
      routesWithTitle.SEASONS,
      routesWithTitle.WORKS,
      routesWithTitle.STATISTICS,
      // routesWithTitle.HIGHLIGHTS,
    ],
  },
  artists: {
    title: `${TP}.m_ARTISTS`,
    items: [routesWithTitle.ARTISTS, routesWithTitle.MANAGERS, routesWithTitle.COMPOSERS],
  },
  organizations: {
    title: `${TP}.FN_ARTS_ORGANISATIONS`,
    items: [
      {
        ...routesWithTitle.ORGANIZATIONS,
        title: `${TP}.FN_ARTS_ORGANISATIONS`,
      },
      routesWithTitle.COMPANIES,
      routesWithTitle.FESTIVALS,
      routesWithTitle.COMPETITIONS,
      routesWithTitle.RENTALS,
      routesWithTitle.VENUES,
    ],
  },
  videos: {
    title: `${TP}.m_VIDEOS`,
    items: [routesWithTitle.VIDEOS],
  },
};

const proLinks = {
  industry: {
    key: 'industry',
    title: `${TP}.FN_PRO_INDUSTRY`,
    maxText: true,
    proLinks: true,
    items: [
      {
        ...routesWithTitle.DETAILS_AOS,
        subTitle: `${TP}.m_PROAO_SUBTEXT`,
      },
      routesWithTitle.DETAILS_AGENTS,
      routesWithTitle.DETAILS_ARTISTS,
      routesWithTitle.FOR_ACADEMIA,
      routesWithTitle.FOR_JOURNALISTS,
    ],
  },
  proTools: {
    key: 'proTools',
    title: `${TP}.FN_PRO_TOOL`,
    items: [
      {
        ...routesWithTitle.CASTING_TOOL,
        isPro: true,
      },
      routesWithTitle.SEASON_PLANNER,
    ],
  },
  audience: {
    key: 'audience',
    title: `${TP}.FN_AUDIENCE`,
    minText: true,
    proLinks: true,
    items: [routesWithTitle.INTRODUCTION_HISTORY, routesWithTitle.CONTACT_OB],
  },
};

const shortLinks = [
  { title: 'operabase.general.m_PERF', link: routesWithTitle.PRODUCTIONS },
  { title: 'operabase.general.m_ARTISTS', link: routesWithTitle.ARTISTS },
  { title: 'operabase.general.m_OPCOS', link: routesWithTitle.COMPANIES },
  { title: 'operabase.general.m_REFERENCE', link: routesWithTitle.DETAILS_AGENTS },
];

const ProLabel = () => (
  <Typography variant="span" className={classes.proLabel}>
    Pro
  </Typography>
);

const Footer = ({ className, isLoggedIn }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const track = useTracking();

  return (
    <footer
      className={classnames(classes.root, className, {
        [classes.footerForPaywall]: !isLoggedIn,
      })}
    >
      <div className={classes.container}>
        <div className={classes.languageSelector}>
          <LanguageSelector type="footer" />
        </div>
        <div className={classes.content}>
          <div className={classes.cell}>
            <Image
              src={operaWhiteLogo}
              width={190}
              height={22}
              alt="Operabase"
              title="Operabase"
              disableNextImage
              disableCloudinary
            />
            <Typography variant="p" className={classes.description} color="inherit">
              {t(`${TP}.m_FOOTER_DESCRIPTION`)}
            </Typography>
            <div className={classes.socialLinks}>
              {socialLinks.map(({ link, type, title }) => (
                <Link
                  key={type}
                  title={title}
                  rel="noopener noreferrer"
                  href={link}
                  className={classes.socialLink}
                  external
                  trackingData={{
                    section: SECTIONS.FOOTER,
                    component: COMPONENTS.SOCIAL_LINKS,
                    subComponent: title,
                    meta: {
                      key: type, // key - facebook
                      title, // label - Facebook
                    },
                  }}
                >
                  <SpriteIcon icon={type} size={32} hover />
                </Link>
              ))}
            </div>
          </div>
          <div className={classnames(classes.cell, classes.shortLinks)}>
            {shortLinks.map((link, index) => (
              <div key={`${link?.link?.path}-${index}`} className={classes.cell__link}>
                <Typography size="14" weight="bold" color="inherit">
                  <Link href={link?.link?.path} {...(link.link?.as && { as: link.link?.as })} className={classes.link}>
                    {t(link?.title)}
                  </Link>
                </Typography>
              </div>
            ))}
          </div>
          {Object.keys(footerLinks).map(key => (
            <div key={key} className={classnames(classes.cell, classes.footerLinks)}>
              <Typography variant="p" size="16" weight="bold" color="inherit" className={classes.sectionHeader}>
                {t(footerLinks[key].title)}
              </Typography>
              <div>
                {footerLinks[key].items.map((item, index) => (
                  <div key={`${item?.path}-${index}`} className={classes.cell__link}>
                    <Typography size="12" color="inherit">
                      <Link
                        href={item?.path}
                        {...(item.as && { as: item.as })}
                        className={classes.link}
                        trackingData={{
                          section: SECTIONS.FOOTER,
                          component: COMPONENTS.NAVIGATE_CTA,
                          // Should be key of the item instead of title (key is not available, example routesWithTitle.PRODUCTIONS doesnt have key)
                          subComponent: t(item?.title),
                          meta: {
                            section: key,
                            title: t(item?.title),
                          },
                        }}
                      >
                        {t(item?.title)} {item?.isPro && <ProLabel isBadge={false} />}
                      </Link>
                    </Typography>
                  </div>
                ))}
              </div>
            </div>
          ))}
          <div className={classnames(classes.cell, classes.proLinks)}>
            <div className={classes.cell__section}>
              <Typography variant="p" size="16" weight="bold" color="inherit" className={classes.sectionHeader}>
                {t(proLinks.industry?.title)}
              </Typography>
              <div>
                {proLinks.industry.items.map((item, index) => (
                  <div key={`${item?.path}-${index}`} className={classes.cell__link}>
                    <Typography size="12" color="inherit">
                      <Link
                        href={item.path}
                        className={classes.link}
                        trackingData={{
                          section: SECTIONS.FOOTER,
                          component: COMPONENTS.NAVIGATE_CTA,
                          subComponent: t(item?.title),
                          meta: {
                            section: proLinks.industry.key,
                            title: t(item?.title),
                            isPro: proLinks.industry.proLinks,
                          },
                        }}
                      >
                        {t(item.title)} {item.isPro && <ProLabel isBadge={false} />}
                      </Link>
                    </Typography>
                  </div>
                ))}
              </div>
            </div>
            <div className={classes.cell__section}>
              <Typography variant="p" size="16" weight="bold" color="inherit" className={classes.sectionHeader}>
                {t(proLinks.proTools?.title)}
              </Typography>
              <div>
                {proLinks.proTools.items.map((item, index) => (
                  <div key={`${item?.path}-${index}`} className={classes.cell__link}>
                    <Typography size="12" color="inherit">
                      <Link
                        href={item.path}
                        {...(item.as && { as: item.as })}
                        className={classes.link}
                        trackingData={{
                          section: SECTIONS.FOOTER,
                          component: COMPONENTS.NAVIGATE_CTA,
                          subComponent: t(item?.title),
                          meta: {
                            section: proLinks.proTools.key,
                            title: t(item?.title),
                            isPro: item.isPro,
                          },
                        }}
                      >
                        {t(item.title)} {item.isPro && <ProLabel isBadge={false} />}
                      </Link>
                    </Typography>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={classnames(classes.cell, classes.audienceLinks)}>
            <Typography variant="p" size="16" weight="bold" color="inherit" className={classes.sectionHeader}>
              {t(proLinks.audience?.title)}
            </Typography>
            <div>
              {proLinks.audience.items.map((item, index) => (
                <div key={`${item?.path}-${index}`} className={classes.cell__link}>
                  <Typography size="12" color="inherit">
                    <Link
                      href={item.path}
                      className={classes.link}
                      trackingData={{
                        section: SECTIONS.FOOTER,
                        component: COMPONENTS.NAVIGATE_CTA,
                        subComponent: t(item?.title),
                        meta: {
                          section: proLinks.audience.key,
                          title: t(item?.title),
                          isPro: proLinks.audience.proLinks,
                        },
                      }}
                    >
                      {t(item.title)} {item.isPro && <ProLabel isBadge={false} />}
                    </Link>
                  </Typography>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className={classes.getInTouch}>
        <Typography variant="p" size="16" weight="bold" color="inherit" className={classes.sectionHeader}>
          {t(`${TP}.FN_GET_IN_TOUCH`)}
        </Typography>
        <div className={classes.getInTouch__row}>
          <SpriteIcon icon="chat" />
          <div className={classes.getInTouch__label}>
            <button
              type="button"
              onClick={() => {
                summonLiveChat();
                track.click({
                  section: SECTIONS.FOOTER,
                  component: COMPONENTS.LIVE_CHAT,
                });
              }}
              className={classes.getInTouchBtn}
            >
              {t(`${TP}.FN_LIVE_CHAT`)}
            </button>
          </div>
        </div>
        <div className={classes.getInTouch__row}>
          <SpriteIcon icon="email" />

          <div className={classes.getInTouch__label}>
            <a
              className={classes.link}
              href="mailto:contact@operabase.com"
              onClick={() => {
                track.click({
                  section: SECTIONS.FOOTER,
                  component: COMPONENTS.CONTACT_VIA_EMAIL,
                });
              }}
            >
              contact@operabase.com
            </a>
          </div>
        </div>
      </div>
      <div className={classes.policy}>
        {policyLinks.map((link, index) => (
          <Link
            key={`${link?.link}-${index}`}
            rel="nofollow"
            className={classes.link}
            href={link.link}
            {...(link.link.startsWith('http') && { external: true })}
            trackingData={{
              section: SECTIONS.FOOTER,
              component: COMPONENTS.POLICY_LINKS,
              subComponent: link.isText ? link.name : t(link.name),
              meta: {
                title: link.isText ? link.name : t(link.name),
              },
            }}
          >
            {link.isText ? link.name : t(link.name)}
          </Link>
        ))}
      </div>
      <div className={classes.rights}>
        <Typography variant="span" color="inherit">
          <>&#169; {createDate().year()} Operabase Media Limited or its affiliated companies. All rights reserved.</>
        </Typography>
      </div>
    </footer>
  );
};

export default Footer;
